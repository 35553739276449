$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.ModernAppFooter {
  padding: $marginMd2 0;

  &.--useAdColumn {
    padding-right: $adColumnWidth !important
  }

  .Flex > div {
    min-width: 130px;
  }

  li {
    white-space: nowrap;
    margin: $marginSm2 0;
  }

  li img {
    width: 20px;
    height: 20px;
    margin-right: $marginSm2;
  }
}

.ModernAppFooter__Logo {
  margin-bottom: $marginSm3;
  height: 41px;
  width: auto;
}
