$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DailyAppHeader {
  height: 48px;
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  z-index: 1;

  .DailyAppHeader__Inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 $spacing;

    position: relative;
    z-index: 2;

    > aside {
      margin-left: auto;
    }

    .Buttons {
      .Button {
        min-width: 110px;
      }
    }
  }

  .DailyAppHeader__ToggleSidebar {
    * {
      pointer-events: none;
    }
  }

  .DailyAppHeader__ToggleFavorites {
    box-sizing: content-box;
    padding-left: $spacing;
    padding-right: $spacing;
  }

  img, 
  .Icon {
    cursor: pointer;
  }
}

.DailyAppHeader__MobileLogo {
  @media (min-width: 341px) {
    display: none;
  }
}

.DailyAppHeader__DesktopLogo {
  display: none;

  @media (min-width: 341px) {
    display: block;
  }
}
