$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DailySidebarMenu {
  position: fixed;
  top: 48px;
  left: -90%;
  bottom: 0;
  width: 90%;
  z-index: 10;

  transition: transform 0.2s ease-in-out;
  
  display: flex;
  align-items: center;

  &__ScrollContainer {
    padding: $marginMd0;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 0;
  }

  &__Footer {
    padding: $marginMd0;

    .Button + .Button {
      margin-left: $marginMd0;
    }
  }

  &__ThemeSwitcher {
    cursor: pointer;
  }

  &--isOpen {
    transform: translateX(100%);
  }

  @include isDesktopDevice {
    $desktopWidth: 360px;

    top: 48px;
    width: $desktopWidth;
    left: -$desktopWidth;
  }

  .Container,
  .Container__Inner {
    height: 100%;
    flex-direction: column;
  }

  .DownloadAccordianCaret {
    height: 0.5rem;
    width: 0.5rem;
    font-size: 50%;
    
    margin-right: 0.75rem;

    transition: transform .1s ease-out;

    &--isOpen {
      transform: rotate(90deg);
    }
  }

  h4 {
    font-size: 12px;
  }

  .LinkList {
    font-size: 12px;

    a {
      cursor: pointer;
      display: flex;

      + a {
        margin-top: $marginSm2;
      }
    }

    figure {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;

      margin-right: $marginSm1;
    }

    &--collapsed {
      height: 0;
      overflow: hidden;
    }

  }

  .Block:not(:last-child) {
    margin-bottom: $spacing * 2;
  }

  .Copyright,
  .AboutUs {
    font-size: 12px;
    margin-top: $spacing;

    a {
      text-decoration: underline;
    }
  }
}

.DailySidebarMenu__SearchInput {
  height: 38px;
  width: 100%;
  position: relative;

  .Icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    height: 1rem;
    width: 1rem;
    color: #A4A4A4;
  }

  input {
    height: 38px;
    width: 100%;
    background: #EDEDED;
    border: solid 1px #DFDFDF;
    font-size: 12px;
    padding: 0 $marginMd0 0 50px;

    &::placeholder {
      color: #A4A4A4;
    }
  }
}