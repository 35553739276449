$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.PromoAppBanner {
  padding: 8px;
  width: 100%;
  text-align: center;
  line-height: 1.1;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #F90;
  cursor: pointer;

  a {
    text-decoration: underline;
    
    &:hover {
      color: #fff !important
    }
  }
}
