$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.FluidLayout {
  .App__Header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  .App__Main {
    margin: $spacing auto;
    width: 100%;
    margin-top: 48px;
    $asideWidth: $adColumnWidth;

    .FluidLayout__Content {
      height: 100%;
    }

    .FluidLayout__Aside {
      display: none;

      width: $asideWidth;
      min-width: $asideWidth;
      max-width: $asideWidth;

      position: fixed;
      top: 48px + 16px;
      right: 16px;
      height: calc(100% - 48px - 32px);
      overflow: hidden;

      @media (min-width: 1280px) {
        display: block;
      }

      + .FluidLayout__Content {
        @media (min-width: 1280px) {
          .Container {
            .Container__Inner {
              box-sizing: content-box;
              padding-right: $asideWidth;
            }
          }
        }

        @media (min-width: 1714px) {
          .Container {
            .Container__Inner {
              padding-left: $asideWidth;
            }
          }
        }

        + .FluidLayout__Aside {
          display: none;
          right: auto;
          left: 16px;

          @media (min-width: 1714px) {
            display: block;
          }
        }
      }
    }

    @include isDesktopDevice {
      margin: ($spacing * 2) auto;
      margin-top: 48px;
    }
  }

  &--restrictHeight {
    flex-basis: 0;
    margin-bottom: 0;

    .App__Main {
      height: calc(100% - 48px);
      margin-bottom: 0;
    }
  }

  &--classicColumnWidth {
    .App__Main {
      .FluidLayout__Aside {
        top: 70px + 16px;
        height: calc(100% - 70px - 32px);
      }
    }

    .FluidLayout__Content {
      .Container__Inner {
        padding: 40px 0 $spacing 0;
        max-width: 728px !important;
        box-sizing: content-box;
      }
    }
  }
}
