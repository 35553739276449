$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.FavoritesDrawer {
  $tileWidth: 180px;

  position: fixed;
  z-index: 1;

  left: 0;
  width: 100%;
  top: 48px;

  padding: $marginMd1;
  transform: translateY(-100%);
  transition: all 240ms ease;

  display: flex;
  justify-content: center;

  &--isOpen {
    transform: translateY(0);
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  }

  .FavoritesGridContainer {
    margin-left: auto;
    margin-right: auto;

    &--isEmpty {
      width: 70%;
      max-width: 800px;
    }
  }

  .FavoritesGrid {
    display: inline-grid;
    grid-template-columns: repeat(6, $tileWidth);
    grid-gap: $spacing;

    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1366px) {
      grid-template-columns: repeat(4, $tileWidth);
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(3, $tileWidth);
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(2, $tileWidth);
    }

    &__More > div > .Icon {
      margin-top: -1px;
    }

    &__Game,
    &__More {
      z-index: 1;
      position: relative;
      display: block;
      width: 100%;
      aspect-ratio: 1.8;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: $radius;


      display: flex;
      align-items: center;
      justify-content: center;

      &:hover:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 2;
      }

      >img {
        width: 100%;
        height: auto;
      }
      
    }

  }

  .EmptyFavorites {
    width: 100%;

  }

  .FavoritesHeader {
    text-align: left;
    font-weight: bold;
    margin-bottom: $spacing;
  }

}