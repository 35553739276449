$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.AppFooter {
  padding: $marginMd2 0;

  .Content {
    p {
      font-size: 80%;
      font-weight: 600;

      &:last-of-type {
        margin-bottom: $spacing;
      }
    }
  }
}
